import React from 'react';
import Layout from '../components/layout';
import StyledHero from "../components/StyledHero";
import About from "../components/About";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import Seo from '../components/SEO';

const about = (props) => {
  const { data } = props;
  console.log("about props:", props);

  const seoImage = {
		src: getSrc(data.seoImage),
		height: data.seoImage.childImageSharp.gatsbyImageData.height,
		width: data.seoImage.childImageSharp.gatsbyImageData.width,
		alt: 'Golden Gate Bridge San Francisco',
  };
  
  return (
    <Layout>
      <Seo
        siteTitle={'About'}
        seoTitle='About Us | Malaud LLC'
        path={props.location.pathname}
        metaImage={seoImage}
        description='Malaud LLC About Page'
        keywords={[]}
        datePublished="04-03-2020"
        dateModified="11-01-2022"
      />            
      
      <StyledHero overlay={true} img={data.defaultBcg.childImageSharp.gatsbyImageData} />
      <About />
    </Layout>
  );
}

export default about

export const aboutQuery = graphql`{
  defaultBcg: file(relativePath: {eq: "aboutBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
  seoImage: file(relativePath: {eq: "aboutBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 600)
    }
  }
}
`
