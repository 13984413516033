import React from "react"
import Title from "./Title"

import * as styles from "../css/products.module.css"
import img from "../images/about.jpg"

const About = () => {
    return (
        <section className={styles.about}>
        <Title title="About" subtitle="Us"/>
        <div className={styles.aboutCenter}>
            <article className={styles.aboutImg}>
                <div className={styles.imgContainer}>
                    <img src={img} alt="about company" />
                </div>
            </article>
            <article className={styles.aboutInfo}>
                <h4>Our Purpose</h4>
                <p>We strive to enrich peoples' lives by making information more 
                    accessible, by providing resources to enhance productivity 
                    and finally, to help you achieve your own goals.</p>
                <p></p>
                <p>We are a consumer focused organization building a range of 
                    products and services that everyone can use, from kids to older adults
                    to people with disabilities. We believe technology can help everyone.
                    Our guiding principle is to build products that we and our families can 
                    use ourselves. This helps us focus on the quality and richness in everything we do.</p>
            </article>
        </div>
        </section>
    )
}

export default About
